import { createReducer, on } from '@ngrx/store';
import { productsActions } from '../actions/products.actions';
import { ProductsValue } from '../models';
import { getKey } from '../utils/get-key';
import { Product } from '@app/store/models';

export interface ProductResult {
    products: Product[] | null;
    totalItems: number | null;
    loaded: null | boolean;
}

export interface ProductsState {
    results: Map<string, ProductResult>;
    current: {
        key: string;
        params: ProductsValue;
    } | null;
}

export const initialState: ProductsState = {
    results: new Map(),
    current: null,
};

export const productsReducer = createReducer(
    initialState,
    on(productsActions.getProducts, (state, { params }): ProductsState => {
        const results = new Map(state.results);
        const key = getKey(params);

        if (!results.has(key)) {
            results.set(key, { products: null, totalItems: null, loaded: null });

            return {
                ...state,
                results,
                current: {
                    key,
                    params,
                },
            };
        }

        return {
            ...state,
            results,
            current: {
                key,
                params,
            },
        };
    }),
    on(productsActions.getProductsParamsSuccess, (state, { items, totalItems, params }): ProductsState => {
        const results = new Map(state.results);
        const key = getKey(params);

        results.set(key, { products: items, totalItems: totalItems, loaded: true });

        return {
            ...state,
            results,
        };
    }),
    on(productsActions.getProductsParamsError, (state, { params }): ProductsState => {
        const results = new Map(state.results);
        const key = getKey(params);

        results.set(key, { products: [], totalItems: 0, loaded: false });

        return {
            ...state,
            results,
        };
    }),
    on(
        productsActions.clearResults,
        (state): ProductsState => ({
            ...state,
            results: new Map(),
        }),
    ),
    on(
        productsActions.clear,
        (): ProductsState => ({
            ...initialState,
            results: new Map(),
        }),
    ),
);
