import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of, catchError, filter, map, switchMap } from 'rxjs';
import { HttpService } from '../services/http.service';
import { productsActions } from '../actions/products.actions';
import { Store } from '@ngrx/store';
import { selectCurrentParams, selectResultByKey } from '../selectors/products.selector';
import { shippingAddressesActions } from '@app/store/shipping-addresses/actions/shipping-addresses.actions';
import { getKey } from '../utils/get-key';
import { ProductsValue } from '../models';

@Injectable()
export class ProductsEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly store = inject(Store);

    getProducts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productsActions.getProducts),
            map(({ params, lang }) => ({ key: getKey(params), lang })),
            concatLatestFrom(({ key }) => this.store.select(selectResultByKey(key))),
            map(([{ lang }]) => productsActions.getProductsParams({ lang })),
        );
    });

    getProductsParams$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productsActions.getProductsParams),
            concatLatestFrom(() => this.store.select(selectCurrentParams).pipe(filter((params): params is ProductsValue => params !== null))),
            switchMap(([{ lang }, params]) => {
                return this.httpService.getProducts(params, lang).pipe(
                    map(({ items, totalItems }) => productsActions.getProductsParamsSuccess({ items, totalItems, params })),
                    catchError(() => of(productsActions.getProductsParamsError({ params }))),
                );
            }),
        );
    });

    clearResults$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddress),
            map(() => productsActions.clearResults()),
        );
    });
}
