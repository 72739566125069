import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PRODUCTS_FEATURE_KEY } from '../keys';
import { ProductsState } from '../reducers/products.reducer';

const selectState = createFeatureSelector<ProductsState>(PRODUCTS_FEATURE_KEY);

const selectCurrentResult = createSelector(selectState, (state: ProductsState) => (state.current?.key ? state.results.get(state.current.key) : null));
const selectResultByKey = (key: string) => createSelector(selectState, (state: ProductsState) => state.results.get(key) || null);

const selectProducts = createSelector(selectCurrentResult, (result) => result?.products ?? null);
const selectTotalItems = createSelector(selectCurrentResult, (result) => result?.totalItems ?? null);
const selectLoading = createSelector(selectCurrentResult, (result) => result?.loaded === null);
const selectCurrentParams = createSelector(selectState, (state: ProductsState) => state.current?.params ?? null);

export { selectResultByKey, selectProducts, selectTotalItems, selectCurrentParams, selectLoading };
