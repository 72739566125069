import { Injectable } from '@angular/core';
import { LocalStorage } from '@app/shared/classes';

@Injectable({ providedIn: 'root' })
export class AddressAlertService {
    private storageKey = 'address-alert-displayed';

    get() {
        return LocalStorage.read<boolean>(this.storageKey);
    }

    set() {
        LocalStorage.write(this.storageKey, true);
    }

    clear() {
        LocalStorage.remove(this.storageKey);
    }
}
