import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { Product, ProductDto } from '@app/store/models';
import { ProductDescriptionDto } from '../models/product-description.dto';
import { Lang } from '@app/i18n/lang.type';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getProductDetails(id: string, lang: Lang) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ProductDto>>(`api/v1/items/${id}`, { headers: { 'Accept-Language': lang } })
            .pipe(map((response) => new Product(response.data)));
    }

    getProductDescription(productId: string, lang: Lang) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ProductDescriptionDto>>(`api/v1/items/marketing/text/${productId}`, { headers: { 'Accept-Language': lang } })
            .pipe(map((response) => response.data.description));
    }

    getProductCard(productNo: string, lang: Lang) {
        return this.httpClient.addAllSwitches().get(`api/v1/cards/items/${productNo}`, { headers: { 'Accept-Language': lang }, responseType: 'blob' });
    }
}
