import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ProductFacade } from '../facades/product.facade';

export function productCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const productFacade = inject(ProductFacade);
        productFacade.clear();
        return true;
    };
}
