import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PRODUCT_FEATURE_KEY } from '../keys';
import { ProductState } from '../reducers/product.reducer';

const selectState = createFeatureSelector<ProductState>(PRODUCT_FEATURE_KEY);

const selectLoaded = createSelector(selectState, (state) => state.loaded);
const selectProductDetails = createSelector(selectState, (state) => state.productDetails);
const selectProductDescription = createSelector(selectState, (state) => state.productDescription);

export { selectLoaded, selectProductDetails, selectProductDescription };
