import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiListingResponse } from '@app/shared/models';
import { Product, ProductDto } from '@app/store/models';
import { map } from 'rxjs';
import { ProductsValueDto } from '../models/products.value.dto';
import { ProductsValue } from '../models/products.value';
import { Lang } from '@app/i18n/lang.type';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getProducts(value: ProductsValue, lang: Lang) {
        const { params } = new ProductsValueDto(value);

        return this.httpClient
            .addAllSwitches()
            .get<ApiListingResponse<ProductDto[]>>(`api/v1/items`, { params, headers: { 'Accept-Language': lang } })
            .pipe(
                map((response) => ({
                    items: response.data.map((productDto) => new Product(productDto)),
                    totalItems: response.pagination.totalItems,
                })),
            );
    }
}
