import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ProductsFacade } from '../facades/products.facade';

export function productsCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const productsFacade = inject(ProductsFacade);
        productsFacade.clear();
        return true;
    };
}
