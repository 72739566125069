import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PRODUCTS_FEATURE_KEY } from './keys';
import { productsReducer } from './reducers/products.reducer';
import { ProductsEffects } from './effects/products.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(PRODUCTS_FEATURE_KEY, productsReducer), EffectsModule.forFeature([ProductsEffects])],
})
export class ProductsStoreModule {}
