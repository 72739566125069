import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PRODUCT_FEATURE_KEY } from './keys';
import { productReducer } from './reducers/product.reducer';
import { ProductEffects } from './effects/product.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(PRODUCT_FEATURE_KEY, productReducer), EffectsModule.forFeature([ProductEffects])],
})
export class ProductStoreModule {}
