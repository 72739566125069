import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectFilters, selectLoaded } from '../selectors/product-filters.selector';
import { productFiltersActions } from '../actions/product-filters.actions';

@Injectable({
    providedIn: 'root',
})
export class ProductFiltersFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    filters$ = this.store.select(selectFilters);

    getFilters(categoryCode: string): void {
        this.store.dispatch(productFiltersActions.getFilters({ categoryCode }));
    }

    clear(): void {
        this.store.dispatch(productFiltersActions.clear());
    }
}
