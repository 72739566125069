import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProductFilter } from '../models';

export const productFiltersActions = createActionGroup({
    source: 'Product Filters',
    events: {
        'Get Filters': props<{ categoryCode: string }>(),
        'Get Filters Success': props<{ filters: ProductFilter[] }>(),
        'Get Filters Error': emptyProps(),

        Clear: emptyProps(),
    },
});
